<template>
  <div class="tempNotice">
    <div class="tempNotice-t">{{ detail.name }}</div>
    <div class="tempNotice-c"><img src="./img/info.png" alt="" /></div>
    <div class="tempNotice-b">
      <p>各位居民朋友：</p>
      <p>{{ detail.neighborhoodText }}</p>
      <p>感谢您的支持和理解，谢谢!</p>
    </div>
    <div class="time">2024年12月17日</div>
    <!-- <v-h5Mtml :content="detail.neighborhoodText"></v-h5Mtml> -->
  </div>
</template>

<script>
import { getNeighborhoodConventionUrl } from "./api.js";
export default {
  name: "tempNotice",
  data() {
    return {
      detail: {
        name: "智邻高新小程序关停通知",
        neighborhoodText: `因系统升级，“智邻高新”微信小程序将在12月25日（周三）上午9点临时关闭，计划于12月27日（周五）下午8点恢复正常，由此给您带来的不便，敬请谅解。衷心感谢一直以来对“智邻高新”微信小程序的关注和支持。`,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
  },
  async mounted() {
    if (window.location.origin != "https://test.zhuneng.cn") {
      this.detail.neighborhoodText = `根据工信部的安全要求，小程序正在进行服务器备案域名迁移操作，在此期间需要停服一段时间，我们预计将于明日（2024年7月2日
        18:00前）恢复服务，给大家带来不便敬请谅解!`;
      this.detail.name = "乐养塔山小程序关停通知";
    }
    // this.getContent();
  },
  methods: {
    getContent() {
      //获取合约内容
      let params = {
        houseId: this.communityId,
      };
      this.$axios
        .get(`${getNeighborhoodConventionUrl}`, {
          params: params,
        })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.detail.neighborhoodText = res.data.neighborhoodText;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.tempNotice {
  padding: 134px 42px;
  .tempNotice-t {
    text-align: center;
    padding: 0 100px;
    font-size: 38px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 52px;
  }
  .tempNotice-c {
    width: 400px;
    height: 400px;
    margin: 44px auto 42px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tempNotice-b {
    text-indent: 2em;
    font-size: 30px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 48px;
  }
  .time {
    margin-top: 56px;
    text-align: right;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
  }
}
</style>
